<template>
    <component :is="component" v-if="component" :memberData="memberData"  />
</template>

<script>
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"
    export default {
        name: "MemberDetailDefaultLayout",
        mixins: [],
        components: {
        },
        props: {
            memberData: {
                type: Object,
                default: () => {return {}},
            },
        },
        data() {
            return {
                type: 1, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            component() {
                if(this.memberData.mb_type === 1) {
                    return () => import(`@/template/member/detail/MemberDetailDefaultMentorLayout`)
                }else if(this.memberData.mb_type === 2) {
                    return () => import(`@/template/member/detail/MemberDetailDefaultMenteeLayout`)
                }else{
                    return null;
                }
            }

        },
        methods:{
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>